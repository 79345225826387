import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
 //   const siteUrl = 'http://localhost:8000'
    const { previous, next } = this.props.pageContext
    //console.log(post)
    //console.log(next);
    let evt = undefined;
    console.log('sadasds', post.frontmatter.name)
    if (post.frontmatter.name) {
      evt = {
        name: post.frontmatter.name,
        team1: post.frontmatter.team1,
        team2: post.frontmatter.team2,
        place: post.frontmatter.place
      };
    }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={`${this.props.data.site.siteMetadata.siteUrl}${post.frontmatter.cover.publicURL}`}
          isBlogPost={true}
          datePublished={post.frontmatter.date}
          event={evt}
        />
        <div className="row">
          <article class="archive-post mts-post spp col-md-9 m-auto">
            <header className="post-content">

              <div class="post-meta">
                <h1 className="w-article-header__headline">{post.frontmatter.title}</h1>

                <span class="thetime updated">{post.frontmatter.date}</span>
              </div>
            </header>
            <div class="post-entry">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
            <div className="post-footer">
              {post.frontmatter.tags !== null ?
                post.frontmatter.tags.map(tag => (
                  <Link to={`/tags/${SlugLink(tag)}`} style={{ marginLeft: 5, color: '#FFF' }} key={tag}><Button variant="dark" style={{ margin: 2 }}>{tag}</Button></Link>
                ))
                :
                null
              }
            </div>
          </article>
        </div>
        <div className="row justify-content-center">
          <div className="col-10 text-center item-relate-h">Bài Viết Mới</div>
          <Col xs={12} md={4} className="item-relate">
            {previous && (
              <article class="related-post mts-post">
                <Link to={previous.fields.slug} title={previous.frontmatter.title} class="post-image post-image-related">
                  <div class="featured-thumbnail">
                  <LazyLoadImage
                        className="wp-post-image"
                        alt={previous.frontmatter.title}
                        title={previous.frontmatter.title}
                        src={siteUrl + previous.frontmatter.cover.childImageSharp.fluid.src}
                        effect="blur"
                      />
                  </div>
                </Link>
                <div class="post-content">
                  <header>
                    <h3 class="post-title"><Link to={previous.fields.slug} title={previous.frontmatter.title}>{previous.frontmatter.title}</Link></h3>
                  </header>
                  
                </div>
              </article>
            )}
          </Col>
          <Col xs={12} md={4} className="item-relate">
            {next && (
              <article class="related-post mts-post clearfix">
                <Link to={next.fields.slug} title={next.frontmatter.title} class="post-image post-image-related">
                  <div class="featured-thumbnail">
                    <LazyLoadImage
                        className="wp-post-image"
                        alt={next.frontmatter.title}
                        title={next.frontmatter.title}
                        src={siteUrl + next.frontmatter.cover.childImageSharp.fluid.src}
                        effect="blur"
                      />
                  </div>
                </Link>
                <div class="post-content">
                  <header>
                    <h2 class="post-title">
                      <Link to={next.fields.slug} title={next.frontmatter.title}>{next.frontmatter.title}</Link></h2>
                  </header>
                </div>
              </article>
            )}
          </Col>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        name
        team1
        team2
        place
        cover {
          publicURL
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
